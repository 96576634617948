import React, { useState, useEffect, useContext } from 'react';
import { toast } from 'react-toastify';
import { Input, Row, Breadcrumb, Button, Form, Spin, Space, Col } from 'antd';
import {
  LeftOutlined,
  MinusCircleTwoTone,
  PlusCircleTwoTone,
} from '@ant-design/icons';
import * as ROUTES from '../../constants/Routes';
import { Link, useParams } from 'react-router-dom';
import { withRouter } from 'react-router';
import { Auth } from '../../context/AuthContext';
import app from '../../firebaseConfig';
import 'antd/dist/antd.css';

const UpdateClient = ({ history }) => {
  const { usuario } = useContext(Auth);
  const { id } = useParams();
  const [clientes, setClientes] = useState({});
  const [loading, setLoading] = useState(true);
  const [submit, setSubmit] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    if (usuario === null) {
      history.push('/login');
    }
    if (usuario !== null) {
      usuario.getIdTokenResult().then(function (idToken) {
        if (idToken.claims.role !== 'admin') {
          history.push('/');
        } else {
          setLoading(true);
          app
            .firestore()
            .collection('Clientes')
            .doc(id)
            .get()
            .then(function (doc) {
              form.setFieldsValue({ ...doc.data() });
              setLoading(false);
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      });
    }
  }, [history, usuario, id, form]);

  const onFinish = (values) => {
    setSubmit(true);
    app
      .firestore()
      .collection('Clientes')
      .doc(id)
      .update({
        nombre: values.nombre,
        plantas: values.plantas,
        caracterizaciones: values.caracterizaciones,
      })
      .then(function () {
        toast.info('🚀 Se ha guardado correctamente!');
        setSubmit(false);
        history.push(ROUTES.CLIENT_LIST);
      })
      .catch(function (error) {
        toast.error('😲 Error en los datos!');
      });
  };

  return (
    <div className="add-control">
      <Breadcrumb separator="/" className="breadcrumb-page">
        <Link to={ROUTES.CLIENT_LIST}>
          <Button
            className="button-back"
            type="primary"
            shape="round"
            icon={<LeftOutlined />}
            size={24}
          />
        </Link>
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item>Añadir Usuario</Breadcrumb.Item>
      </Breadcrumb>
      <Spin spinning={loading} tip="Cargando...">
        <Form
          name="update_client"
          form={form}
          initialValues={clientes}
          onFinish={onFinish}
          className="form-container"
        >
          <h2 style={{ marginBottom: '24px' }}>Cliente</h2>
          <Form.Item
            label=""
            name="nombre"
            rules={[{ required: true, message: 'Campo obligatorio!' }]}
          >
            <Input placeholder="Nombre" className="input-form" type="text" />
          </Form.Item>
          <Row>
            <Col style={{ padding: '16px' }} span={12}>
              <h2 style={{ marginBottom: '24px' }}>
                Plantas de caracterización
              </h2>
              <Form.List name="plantas">
                {(fields, { add, remove }) => (
                  <React.Fragment>
                    {fields.map((field) => (
                      <Space
                        key={field.key}
                        style={{ display: 'flex', marginBottom: 8 }}
                        align="baseline"
                      >
                        <Form.Item
                          {...field}
                          name={[field.name, 'nombre']}
                          fieldKey={[field.fieldKey, 'nombre']}
                          rules={[
                            { required: true, message: 'Campo obligatorio!' },
                          ]}
                        >
                          <Input placeholder="Nombre planta" />
                        </Form.Item>
                        <MinusCircleTwoTone
                          twoToneColor="#ea5455"
                          onClick={() => remove(field.name)}
                        />
                      </Space>
                    ))}
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        block
                        icon={<PlusCircleTwoTone />}
                      >
                        Añadir planta
                      </Button>
                    </Form.Item>
                  </React.Fragment>
                )}
              </Form.List>
            </Col>
            <Col style={{ padding: '16px' }} span={12}>
              <h2 style={{ marginBottom: '24px' }}>Caracterizaciones</h2>
              <Form.List name="caracterizaciones">
                {(fields, { add, remove }) => (
                  <React.Fragment>
                    {fields.map((field) => (
                      <Space
                        key={field.key}
                        style={{ display: 'flex', marginBottom: 8 }}
                        align="baseline"
                      >
                        <Form.Item
                          {...field}
                          name={[field.name, 'nombre']}
                          fieldKey={[field.fieldKey, 'nombre']}
                          rules={[
                            { required: true, message: 'Campo obligatorio!' },
                          ]}
                        >
                          <Input placeholder="Nombre caracterización" />
                        </Form.Item>
                        <MinusCircleTwoTone
                          twoToneColor="#ea5455"
                          onClick={() => remove(field.name)}
                        />
                      </Space>
                    ))}
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        block
                        icon={<PlusCircleTwoTone />}
                      >
                        Añadir caracterización
                      </Button>
                    </Form.Item>
                  </React.Fragment>
                )}
              </Form.List>
            </Col>
          </Row>
          <Form.Item>
            <Button
              className="button-submit"
              type="primary"
              htmlType="submit"
              size={24}
              loading={submit}
            >
              Modificar
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </div>
  );
};

export default withRouter(UpdateClient);
