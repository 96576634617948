import React, { useState, useEffect, useContext } from 'react';
import AdminList from '../AdminList';
import { withRouter } from 'react-router';
import { Auth } from '../../context/AuthContext';
import 'antd/dist/antd.css';
import CardList from '../CardList';
import ConsultorList from '../ConsultorList';

const Layout = ({ history }) => {
  const { usuario } = useContext(Auth);
  const [role, setRole] = useState('');
  const [name, setName] = useState('');

  useEffect(() => {
    if (usuario === null) {
      history.push('/login');
    }
    if (usuario !== null) {
      usuario.getIdTokenResult().then(function (idToken) {
        setRole(idToken.claims.role);
        setName(idToken.claims.name);
      });
    }
  }, [history, usuario]);

  return (
    <div className="card-list-caracterizaciones">
      {role === 'admin' && <AdminList />}
      {role === 'consultor' && <ConsultorList />}
      {role === 'tecnico' && <CardList tecnico={name} />}
    </div>
  );
};

export default withRouter(Layout);
