import React, { useState, useEffect, useContext } from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { Auth } from '../../context/AuthContext';
import { Table, Space, Button, Input, Menu, Dropdown, Spin } from 'antd';
import { EditTwoTone, DeleteTwoTone, MoreOutlined, UserAddOutlined } from '@ant-design/icons';
import Spinner from '../Spinner';
import 'antd/dist/antd.css';
import './index.css';
import Axios from 'axios';

const UserList = ({ history }) => {
	const { usuario } = useContext(Auth);
	const [ users, setUsers ] = useState([]);
	const [ filterUsers, setFilterUsers ] = useState([]);
	const [ loading, setLoading ] = useState();

	const columns = [
		{
			title: 'Nombre',
			dataIndex: 'displayName',
			width: 120,
			sorter: (a, b) => a.displayName.localeCompare(b.displayName),
			ellipsis: true,
			showSorterTooltip: false
		},
		{
			title: 'Correo',
			dataIndex: 'email',
			width: 120,
			sorter: (a, b) =>  a.email.localeCompare(b.email),
			ellipsis: true,
			showSorterTooltip: false
		},
		{
			title: 'Rol',
			dataIndex: 'role',
			width: 120,
			sorter: (a, b) => a.role.localeCompare(b.role),
			ellipsis: true,
			showSorterTooltip: false
		},
		{
			title: 'Cliente',
			dataIndex: 'client',
			width: 120,
			sorter: (a, b) => a.client.localeCompare(b.client),
			ellipsis: true,
			showSorterTooltip: false
		},
		{
			title: ' ',
			key: 'uid',
			width: 60,
			render: (record) => (
				<Dropdown
					overlay={
						<Menu>
							<Menu.Item key="0">
								<Link to={`/updateUsuario/${record.uid}`}>
									<EditTwoTone twoToneColor="#326ee6" /> Editar
								</Link>
							</Menu.Item>
							<Menu.Item key="1">
								<Link to={`/deleteUsuario/${record.uid}`}>
									<DeleteTwoTone twoToneColor="#d94a4a" /> Eliminar
								</Link>
							</Menu.Item>
						</Menu>
					}
					trigger={[ 'click' ]}
					placement="topCenter"
				>
					<a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
						<Space size="middle">
							<MoreOutlined style={{ fontSize: '24px', color: 'black' }} />
						</Space>
					</a>
				</Dropdown>
			)
		}
	];

	useEffect(
		() => {
			if (usuario === null) {
				history.push('/login');
			}
			if (usuario !== null) {
				usuario.getIdTokenResult().then(function(idToken) {
					if (idToken.claims.role !== 'admin') {
						history.push('/');
					} else {
						const config = {
							headers: { Authorization: `Bearer ${idToken.token}` }
						};
						setLoading(true);
						Axios.get(`https://us-central1-smcontrol-60d81.cloudfunctions.net/api/users`, config)
							.then((res) => {
								setUsers(res.data.users);
								setFilterUsers(res.data.users);
								setLoading(false);
							})
							.catch(function(error) {
								// handle error
								console.log(error);
							});
					}
				});
			}
		},
		[ history, usuario ]
	);

	const handleFilter = (e) => {
		const data = users.filter(
			(item) =>
				item.displayName.toLowerCase().startsWith(e.target.value.toLowerCase()) ||
				item.email.toLowerCase().startsWith(e.target.value.toLowerCase()) ||
				item.role.toLowerCase().startsWith(e.target.value.toLowerCase()) ||
				item.client.toLowerCase().startsWith(e.target.value.toLowerCase())
		);
		setFilterUsers(data);
	};

	return (
		<div className="user-table-content">
			<div className="breadcrumb-page search">
				<label>Buscador:</label>
				<Input onChange={handleFilter} />
				<Link to={`/addUsuario`}>
					<Button className="success-button" icon={<UserAddOutlined />} size="large">
						Añadir Usuario
					</Button>
				</Link>
			</div>
			<Spin spinning={loading} tip="Cargando...">
				<Table columns={columns} dataSource={filterUsers} className="table-users" />
			</Spin>
		</div>
	);
};

export default withRouter(UserList);
