import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router';
import { Auth } from '../../context/AuthContext';
import app from '../../firebaseConfig';
import { Breadcrumb, Button, Input, Form, Row, Col, Space } from 'antd';
import * as ROUTES from '../../constants/Routes';
import { LeftOutlined, MinusCircleTwoTone, PlusCircleTwoTone } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import 'antd/dist/antd.css';
import './index.css';
import moment from 'moment';
import 'moment/locale/es';
moment.locale('es');

const AddClient = ({ history }) => {
	const { usuario } = useContext(Auth);
	const [ loading, setLoading ] = useState(false);

	useEffect(
		() => {
			if (usuario === null) {
				history.push('/login');
			}
			if (usuario !== null) {
				usuario.getIdTokenResult().then(function(idToken) {
					if (idToken.claims.role !== 'admin') {
						history.push('/');
					}
				});
			}
		},
		[ history, usuario ]
	);

	const onFinish = (values) => {
		setLoading(true);
		app
			.firestore()
			.collection('Clientes')
			.doc()
			.set({
				nombre: values.nombre,
				fecha: moment().toDate(),
				plantas: values.plantas ? values.plantas : [],
				caracterizaciones: values.caracterizaciones ? values.caracterizaciones : []
			})
			.then(function() {
				toast.info('🚀 Se ha guardado correctamente!');
				setLoading(false);
				history.push(ROUTES.CLIENT_LIST);
			})
			.catch(function(error) {
				toast.error('😲 Error en los datos!');
			});
	};

	return (
		<div className="add-control">
			<Breadcrumb separator="/" className="breadcrumb-page">
				<Link to={ROUTES.CLIENT_LIST}>
					<Button className="button-back" type="primary" shape="round" icon={<LeftOutlined />} size={24} />
				</Link>
				<Breadcrumb.Item>Home</Breadcrumb.Item>
				<Breadcrumb.Item>Añadir Cliente</Breadcrumb.Item>
			</Breadcrumb>
			<Form name="add_client" onFinish={onFinish} className="form-container">
				<h2 style={{ marginBottom: '24px' }}>Cliente</h2>
				<Form.Item label="" name="nombre" rules={[ { required: true, message: 'Campo obligatorio!' } ]}>
					<Input placeholder="Nombre" className="input-form" type="text" />
				</Form.Item>
				<Row>
					<Col style={{ padding: '16px' }} span={12}>
						<h2 style={{ marginBottom: '24px' }}>Plantas de caracterización</h2>
						<Form.List name="plantas">
							{(fields, { add, remove }) => (
								<React.Fragment>
									{fields.map((field) => (
										<Space
											key={field.key}
											style={{ display: 'flex', marginBottom: 8 }}
											align="baseline"
										>
											<Form.Item
												{...field}
												name={[ field.name, 'nombre' ]}
												fieldKey={[ field.fieldKey, 'nombre' ]}
												rules={[ { required: true, message: 'Campo obligatorio!' } ]}
											>
												<Input placeholder="Nombre planta" />
											</Form.Item>
											<MinusCircleTwoTone
												twoToneColor="#ea5455"
												onClick={() => remove(field.name)}
											/>
										</Space>
									))}
									<Form.Item>
										<Button type="dashed" onClick={() => add()} block icon={<PlusCircleTwoTone />}>
											Añadir planta
										</Button>
									</Form.Item>
								</React.Fragment>
							)}
						</Form.List>
					</Col>
					<Col style={{ padding: '16px' }} span={12}>
						<h2 style={{ marginBottom: '24px' }}>Caracterizaciones</h2>
						<Form.List name="caracterizaciones">
							{(fields, { add, remove }) => (
								<React.Fragment>
									{fields.map((field) => (
										<Space
											key={field.key}
											style={{ display: 'flex', marginBottom: 8 }}
											align="baseline"
										>
											<Form.Item
												{...field}
												name={[ field.name, 'nombre' ]}
												fieldKey={[ field.fieldKey, 'nombre' ]}
												rules={[ { required: true, message: 'Campo obligatorio!' } ]}
											>
												<Input placeholder="Nombre caracterización" />
											</Form.Item>
											<MinusCircleTwoTone
												twoToneColor="#ea5455"
												onClick={() => remove(field.name)}
											/>
										</Space>
									))}
									<Form.Item>
										<Button type="dashed" onClick={() => add()} block icon={<PlusCircleTwoTone />}>
											Añadir caracterización
										</Button>
									</Form.Item>
								</React.Fragment>
							)}
						</Form.List>
					</Col>
				</Row>
				<Form.Item>
					<Button className="button-submit" type="primary" htmlType="submit" size={24} loading={loading}>
						Añadir
					</Button>
				</Form.Item>
			</Form>
		</div>
	);
};
export default withRouter(AddClient);
