import React, { useState, useEffect, useContext } from 'react';
import { withRouter } from 'react-router';
import { Auth } from '../../context/AuthContext';
import {
  Table,
  Input,
  Avatar,
  Tooltip,
  Button,
  Space,
  Dropdown,
  Menu,
  Spin,
} from 'antd';
import app from '../../firebaseConfig';
import 'antd/dist/antd.css';
import './index.css';
import XLSX from 'xlsx';
import {
  DownloadOutlined,
  CheckCircleFilled,
  ClockCircleFilled,
  PauseCircleFilled,
  MoreOutlined,
  InfoCircleTwoTone,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/Routes';
import moment from 'moment';
import 'moment/locale/es';
moment.locale('es');

const ConsultorList = ({ history }) => {
  const { usuario } = useContext(Auth);
  const [controles, setControles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filterControles, setFilterControles] = useState([]);
  const columns = [
    {
      title: 'Cliente',
      dataIndex: 'cliente',
      sorter: (a, b) => a.cliente.localeCompare(b.cliente),
      ellipsis: true,
      showSorterTooltip: false,
      width: 150,
    },
    {
      title: 'C.A',
      dataIndex: 'comunidad',
      sorter: (a, b) => a.comunidad.localeCompare(b.comunidad),
      showSorterTooltip: false,
      width: 110,
    },
    {
      title: 'Planta',
      dataIndex: 'planta',
      sorter: (a, b) => a.planta.localeCompare(b.planta),
      showSorterTooltip: false,
      width: 110,
    },
    {
      title: 'Caracterizacion',
      dataIndex: 'caracterizacion',
      sorter: (a, b) => a.caracterizacion.localeCompare(b.caracterizacion),
      ellipsis: true,
      showSorterTooltip: false,
      width: 130,
    },
    {
      title: 'Usuarios',
      key: 'usuarios',
      dataIndex: 'usuarios',
      width: 110,
      render: (usuarios) => (
        <div className="ant-avatar-group">
          {usuarios.map((usuario) => {
            var splits = usuario.split(' ');
            return (
              <Avatar style={{ backgroundColor: '#4a61c2' }}>
                {splits.length >= 1 ? splits[0].charAt(0) : null}
                {splits.length >= 2 ? splits[1].charAt(0) : null}
              </Avatar>
            );
          })}
        </div>
      ),
    },
    {
      title: 'Fecha de Inicio',
      dataIndex: 'horaInicio',
      sorter: (a, b) =>
        moment(a.horaInicio, 'DD/MM/YYYY hh:mm').unix() -
        moment(b.horaInicio, 'DD/MM/YYYY hh:mm').unix(),
      showSorterTooltip: false,
      width: 110,
    },
    {
      title: 'Estado',
      key: 'estado',
      dataIndex: 'estado',
      width: 90,
      render: (estado) => (
        <div>
          {estado === 'Finalizado' && (
            <Tooltip title="Finalizado" placement="top">
              <CheckCircleFilled
                style={{ fontSize: '28px', color: '#7a9e5e' }}
              />
            </Tooltip>
          )}
          {estado === 'En curso' && (
            <Tooltip title="En curso" placement="top">
              <ClockCircleFilled
                style={{ fontSize: '28px', color: '#d94a4a' }}
              />
            </Tooltip>
          )}
          {estado === 'En pausa' && (
            <Tooltip title="En pausa" placement="top">
              <PauseCircleFilled
                style={{ fontSize: '28px', color: '#ebbf2f' }}
              />
            </Tooltip>
          )}
        </div>
      ),
    },
    {
      title: ' ',
      key: 'uid',
      width: 60,
      render: (record) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item key="0">
                <Link to={`/${ROUTES.DETAIL_CONTROL_SIMPLE}/${record.uid}`}>
                  <InfoCircleTwoTone twoToneColor="#326ee6" /> Detalles
                </Link>
              </Menu.Item>
            </Menu>
          }
          trigger={['click']}
          placement="topCenter"
        >
          <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
            <Space size="middle">
              <MoreOutlined style={{ fontSize: '24px', color: 'black' }} />
            </Space>
          </a>
        </Dropdown>
      ),
    },
  ];

  useEffect(() => {
    if (usuario === null) {
      history.push('/login');
    }
    if (usuario !== null) {
      usuario.getIdTokenResult().then(function (idToken) {
        if (idToken.claims.role !== 'consultor') {
          history.push('/');
        } else {
          app
            .firestore()
            .collection('Controles')
            .where('cliente', '==', idToken.claims.client)
            .limit(120)
            .get()
            .then(function (querySnapshot) {
              querySnapshot.forEach(function (doc) {
                const data = {
                  uid: doc.id,
                  ...doc.data(),
                  horaInicio: `${moment(doc.data().horaInicio.toDate()).format(
                    'DD/MM/YYYY HH:mm'
                  )}`,
                  horaPausa: doc.data().horaPausa
                    ? `${moment(doc.data().horaPausa.toDate()).format(
                        'L'
                      )} ${moment(doc.data().horaPausa.toDate()).format('LT')}`
                    : '',
                  horaReanudacion: doc.data().horaReanudacion
                    ? `${moment(doc.data().horaReanudacion.toDate()).format(
                        'L'
                      )} ${moment(doc.data().horaReanudacion.toDate()).format(
                        'LT'
                      )}`
                    : '',
                  horaFinal: doc.data().horaFinal
                    ? `${moment(doc.data().horaFinal.toDate()).format(
                        'L'
                      )} ${moment(doc.data().horaFinal.toDate()).format('LT')}`
                    : '',

                  locationPausa: doc.data().locationPausa
                    ? doc.data().locationPausa
                    : '',
                  locationReanudacion: doc.data().locationReanudacion
                    ? doc.data().locationReanudacion
                    : '',
                  locationFinal: doc.data().locationFinal
                    ? doc.data().locationFinal
                    : '',
                };
                setControles((controles) => [...controles, data]);
                setFilterControles((filterControles) => [
                  ...filterControles,
                  data,
                ]);
                setLoading(false);
              });
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      });
    }
  }, [history, usuario]);

  const handleFilter = (e) => {
    const data = controles.filter(
      (item) =>
        item.planta.toLowerCase().startsWith(e.target.value.toLowerCase()) ||
        item.caracterizacion
          .toLowerCase()
          .startsWith(e.target.value.toLowerCase()) ||
        item.horaInicio
          .toLowerCase()
          .startsWith(e.target.value.toLowerCase()) ||
        item.cliente.toLowerCase().startsWith(e.target.value.toLowerCase()) ||
        item.usuarios
          .toString()
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        item.comunidad
          .toString()
          .toLowerCase()
          .includes(e.target.value.toLowerCase())
    );
    setFilterControles(data);
  };

  const exportFile = () => {
    let controles2 = [
      [
        'Cliente',
        'Comunidad',
        'Planta',
        'Caracterización',
        'Usuarios',
        'Hora de Inicio',
        'Localización Inicio(latitude)',
        'Localización Inicio(longitude)',
        'Hora de Pausado',
        'Localización Pausado(latitude)',
        'Localización Pausado(longitude)',
        'Hora de Reanudación',
        'Localización Reanudación(latitude)',
        'Localización Reanudación(longitude)',
        'Hora Finalización',
        'Localización Finalización(latitude)',
        'Localización Finalización(longitude)',
      ],
    ];

    controles.map((control) => {
      let userArray = [
        control.cliente,
        control.comunidad,
        control.planta,
        control.caracterizacion,
        control.usuarios.toString(),
        control.horaInicio,
        control.locationInicio.latitude,
        control.locationInicio.longitude,
        control.horaPausa ? control.horaPausa : '',
        control.locationPausa ? control.locationPausa.latitude : '',
        control.locationPausa ? control.locationPausa.longitude : '',
        control.horaReanudacion ? control.horaReanudacion : '',
        control.locationReanudacion ? control.locationReanudacion.latitude : '',
        control.locationReanudacion
          ? control.locationReanudacion.longitude
          : '',
        control.horaFinal ? control.horaFinal : '',
        control.locationFinal ? control.locationFinal.latitude : '',
        control.locationFinal ? control.locationFinal.longitude : '',
      ];
      controles2.push(userArray);
    });
    const wb = XLSX.utils.book_new();
    const wsAll = XLSX.utils.aoa_to_sheet(controles2);
    XLSX.utils.book_append_sheet(wb, wsAll, 'Controles');
    XLSX.writeFile(wb, `export-${moment().format('LL')}.xlsx`);
  };

  return (
    <React.Fragment>
      <div className="breadcrumb-page search">
        <label>Buscador:</label>
        <Input onChange={handleFilter} />
        <Button
          className="success-button"
          icon={<DownloadOutlined />}
          size="large"
          onClick={exportFile}
        >
          Exportar a Excel
        </Button>
      </div>
      <Spin spinning={loading} tip="Cargando...">
        <Table
          columns={columns}
          dataSource={filterControles}
          className="table-users"
          pagination={{ showSizeChanger: true }}
        />
      </Spin>
    </React.Fragment>
  );
};

export default withRouter(ConsultorList);
