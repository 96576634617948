import React, { useEffect, useContext } from 'react';
import CardInfo from '../CardInfo';
import { withRouter } from 'react-router';
import { Auth } from '../../context/AuthContext';
import { useCollectionOnce } from 'react-firebase-hooks/firestore';
import Spinner from '../Spinner';
import app from '../../firebaseConfig';
import 'antd/dist/antd.css';
import './index.css';

const CardList = ({ history, ...props }) => {
  const { usuario } = useContext(Auth);
  const [controles, loading, error] = useCollectionOnce(
    app
      .firestore()
      .collection('Controles')
      .where('usuarios', 'array-contains', `${props.tecnico}`)
  );

  useEffect(() => {
    if (usuario === null) {
      history.push('/login');
    }
  }, [history, usuario]);

  return (
    <div>
      {loading && <Spinner />}
      {controles &&
        controles.docs
          .sort(
            (a, b) =>
              b.data().horaInicio.toDate() - a.data().horaInicio.toDate()
          )
          .map((item) => {
            if (item.data().horaFinal) {
              return (
                <CardInfo
                  status="closed"
                  tecnico={props.tecnico}
                  nombre={item.data().caracterizacion}
                  hora={item.data().horaInicio.toDate()}
                  id={item.id}
                />
              );
            }
            if (
              !item.data().horaFinal &&
              item.data().horaPausa &&
              !item.data().horaReanudacion
            ) {
              return (
                <CardInfo
                  status="wait"
                  tecnico={props.tecnico}
                  nombre={item.data().caracterizacion}
                  hora={item.data().horaInicio.toDate()}
                  id={item.id}
                />
              );
            }
            if (
              !item.data().horaFinal &&
              item.data().horaPausa &&
              item.data().horaReanudacion
            ) {
              return (
                <CardInfo
                  status="open"
                  tecnico={props.tecnico}
                  nombre={item.data().caracterizacion}
                  hora={item.data().horaInicio.toDate()}
                  id={item.id}
                />
              );
            }
            if (!item.data().horaFinal && !item.data().horaPausa) {
              return (
                <CardInfo
                  status="open"
                  tecnico={props.tecnico}
                  nombre={item.data().caracterizacion}
                  hora={item.data().horaInicio.toDate()}
                  id={item.id}
                />
              );
            }
          })}
    </div>
  );
};

export default withRouter(CardList);
