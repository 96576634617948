import React from 'react';
import { Button } from 'antd';
import 'antd/dist/antd.css';

const DeleteButton = ({ page, handleSubmit, loading }) => {
	return (
		<div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center' }} className="form-container">
			<h1>¿Seguro que quieres eliminar este {page}?</h1>
			<Button className="button-submit-red" type="primary" size={24} onClick={handleSubmit} loading={loading}>
				Eliminar
			</Button>
		</div>
	);
};

export default DeleteButton;
