import React, { useEffect, useContext, useState } from 'react';
import { toast } from 'react-toastify';
import * as ROUTES from '../../constants/Routes';
import { useParams } from 'react-router-dom';
import { withRouter } from 'react-router';
import { Auth } from '../../context/AuthContext';
import SubHeader from '../SubHeader';
import DeleteButton from '../DeleteButton';
import 'antd/dist/antd.css';
import './index.css';
import Axios from 'axios';

const DeleteUser = ({ history }) => {
	const { usuario } = useContext(Auth);
	const [ submit, setSubmit ] = useState(false);
	const { id } = useParams();

	useEffect(
		() => {
			if (usuario === null) {
				history.push('/login');
			}
			if (usuario !== null) {
				usuario.getIdTokenResult().then(function(idToken) {
					if (idToken.claims.role !== 'admin') {
						history.push('/');
					}
				});
			}
		},
		[ history, usuario ]
	);

	const handleSubmit = (e) => {
		e.preventDefault();
		// ... submit to API or something
		//Get token
		setSubmit(true);
		usuario
			.getIdToken()
			.then(function(idToken) {
				const config = {
					headers: { Authorization: `Bearer ${idToken}` }
				};
				Axios.delete(`https://us-central1-smcontrol-60d81.cloudfunctions.net/api/users/${id}`, config)
					.then((res) => {
						toast.info('🚀 Se ha eliminado correctamente!');
						setSubmit(false);
						history.push(ROUTES.USER_LIST);
					})
					.catch(function(error) {
						// handle error
						toast.error('😲 Error en los datos!');
					});
			})
			.catch(function(error) {
				// handle error
				toast.error('😲 Error en los datos!');
			});
	};

	return (
		<div className="add-control">
			<SubHeader route={ROUTES.USER_LIST} breadcrumbs={[ 'Home', 'Añadir Usuario' ]} />	
		    <DeleteButton page="usuario" handleSubmit={handleSubmit} loading={submit} />
		</div>
	);
};

export default withRouter(DeleteUser);
