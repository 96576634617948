import React, { useEffect, useContext } from 'react';
import Logo from './images/logo.png';
import FormLogin from '../FormLogin';
import { withRouter } from 'react-router';
import { Auth } from '../../context/AuthContext';
import 'antd/dist/antd.css';
import './index.css';

const LogIn = ({ history }) => {
  const { usuario } = useContext(Auth);

  useEffect(() => {
    if (usuario) {
      history.push('/');
    }
  }, [history, usuario]);

  return (
    <div id="login">
      <div className="login-container">
        <div className="wrap-login">
          <div className="form-login">
            <div
              style={{
                maxWidth: '350px',
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
            >
              <img src={Logo} alt="Logo sistemas medioambientales" />
              <h2>Iniciar Sesión</h2>
              <FormLogin />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(LogIn);
