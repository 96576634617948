import React, { useState, useContext, useEffect } from 'react';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router';
import { useCollectionDataOnce } from 'react-firebase-hooks/firestore';
import { Auth } from '../../context/AuthContext';
import app from '../../firebaseConfig';
import { Select, Breadcrumb, Button, Spin, Form } from 'antd';
import * as ROUTES from '../../constants/Routes';
import { LeftOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import 'antd/dist/antd.css';
import './index.css';
import { geolocated } from 'react-geolocated';
import Location from './images/location.svg';
import moment from 'moment';
import 'moment/locale/es';
moment.locale('es');

const { Option } = Select;

const AddControl = ({
  history,
  coords,
  isGeolocationAvailable,
  isGeolocationEnabled,
}) => {
  const { usuario } = useContext(Auth);
  const [submit, setSubmit] = useState(false);
  const [clientes, setClientes] = useState([]);
  const [plantas, setPlantas] = useState([]);
  const [caracterizaciones, setCaracterizaciones] = useState([]);
  const [loading, setLoading] = useState(true);
  const [tecnicos, loading2, error4] = useCollectionDataOnce(
    app.firestore().collection('Usuarios')
  );
  const comunidades = [
    {
      nombre: 'Andalucía',
    },
    {
      nombre: 'Aragón',
    },
    {
      nombre: 'Principado de Asturias',
    },
    {
      nombre: 'Islas Baleares',
    },
    {
      nombre: 'Canarias',
    },
    {
      nombre: 'Cantabria',
    },
    {
      nombre: 'Castilla-La Mancha',
    },
    {
      nombre: 'Castilla y León',
    },
    {
      nombre: 'Cataluña',
    },
    {
      nombre: 'Comunidad Valenciana',
    },
    {
      nombre: 'Extremadura',
    },
    {
      nombre: 'Galicia',
    },
    {
      nombre: 'La Rioja',
    },
    {
      nombre: 'Comunidad de Madrid',
    },
    {
      nombre: 'Región de Murcia',
    },
    {
      nombre: 'Comunidad Foral de Navarra',
    },
    {
      nombre: 'País Vasco',
    },
  ];

  useEffect(() => {
    if (usuario === null) {
      history.push('/login');
    }
    if (usuario !== null) {
      app
        .firestore()
        .collection('Clientes')
        .get()
        .then(function (querySnapshot) {
          querySnapshot.forEach(function (doc) {
            setClientes((clientes) => [...clientes, doc.data()]);
          });
          setLoading(false);
        })
        .catch(function (error) {
          console.log('Error getting documents: ', error);
        });
    }
  }, [history, usuario]);

  /* Selectect handler */
  const handleClienteChange = (value) => {
    setPlantas(clientes.find((cliente) => cliente.nombre === value).plantas);
    setCaracterizaciones(
      clientes.find((cliente) => cliente.nombre === value).caracterizaciones
    );
  };

  const onFinish = (values) => {
    setSubmit(true);
    app
      .firestore()
      .collection('Controles')
      .doc()
      .set({
        ...values,
        horaInicio: moment().toDate(),
        locationInicio: {
          latitude: coords.latitude,
          longitude: coords.longitude,
        },
        estado: 'En curso',
      })
      .then(function () {
        toast.info('🚀 Se ha guardado correctamente!');
        setSubmit(false);
        history.push('/');
      })
      .catch(function (error) {
        toast.error('😲 Error en los datos!');
      });
  };

  return !isGeolocationAvailable ? (
    <div className="location-wrap">
      <img
        src={Location}
        style={{ height: '200px', width: '200px' }}
        alt="location img"
      />
      <h2>Su navegador no admite Geolocalización</h2>
    </div>
  ) : !isGeolocationEnabled ? (
    <div className="location-wrap">
      <img
        src={Location}
        style={{ height: '200px', width: '200px' }}
        alt="location img"
      />
      <h2>La geolocalización no está habilitada</h2>
    </div>
  ) : coords ? (
    <div className="add-control">
      <Breadcrumb separator="/" className="breadcrumb-page">
        <Link to={ROUTES.DASHBOARD}>
          <Button
            className="button-back"
            type="primary"
            shape="round"
            icon={<LeftOutlined />}
            size={24}
          />
        </Link>
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item>Añadir Control</Breadcrumb.Item>
      </Breadcrumb>
      <Spin spinning={loading} tip="Cargando...">
        <Form name="add_control" onFinish={onFinish} className="form-container">
          <Form.Item
            label=""
            name="cliente"
            rules={[{ required: true, message: 'Campo obligatorio!' }]}
          >
            <Select
              placeholder="Selecciona un cliente"
              className="input-form"
              bordered={false}
              onChange={handleClienteChange}
            >
              {clientes.map((cliente) => (
                <Option key={cliente.nombre}>{cliente.nombre}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label=""
            name="comunidad"
            rules={[{ required: true, message: 'Campo obligatorio!' }]}
          >
            <Select
              placeholder="Selecciona una cominudad autónoma"
              className="input-form"
              bordered={false}
            >
              {comunidades
                .sort((a, b) => a.nombre.localeCompare(b.nombre))
                .map((comunidad) => (
                  <Option key={comunidad.nombre}>{comunidad.nombre}</Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item
            label=""
            name="planta"
            rules={[{ required: true, message: 'Campo obligatorio!' }]}
          >
            <Select
              placeholder="Selecciona una planta"
              className="input-form"
              bordered={false}
            >
              {plantas
                .sort((a, b) => a.nombre.localeCompare(b.nombre))
                .map((planta) => (
                  <Option key={planta.nombre}>{planta.nombre}</Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item
            label=""
            name="caracterizacion"
            rules={[{ required: true, message: 'Campo obligatorio!' }]}
          >
            <Select
              placeholder="Selecciona una caracterización"
              className="input-form"
              bordered={false}
            >
              {caracterizaciones
                .sort((a, b) => a.nombre.localeCompare(b.nombre))
                .map((caracterizacion) => (
                  <Option key={caracterizacion.nombre}>
                    {caracterizacion.nombre}
                  </Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item
            label=""
            name="usuarios"
            rules={[{ required: true, message: 'Campo obligatorio!' }]}
          >
            <Select
              mode="multiple"
              placeholder="Selecciona a los tecnicos"
              className="input-form"
              bordered={false}
              notFoundContent={loading2 ? <Spin size="small" /> : null}
            >
              {tecnicos &&
                tecnicos.map((tecnico) => (
                  <Option key={tecnico.nombre}>{tecnico.nombre}</Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item>
            <Button
              className="button-submit"
              type="primary"
              htmlType="submit"
              size={24}
              loading={submit}
            >
              Añadir
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </div>
  ) : (
    <div className="location-wrap">
      <img
        src={Location}
        style={{ height: '200px', width: '200px' }}
        alt="location img"
      />
      <h2>Obteniendo los datos de ubicación...</h2>
      <Spin style={{ marginTop: '20px' }} size="large" />
    </div>
  );
};

export default withRouter(
  geolocated({
    positionOptions: {
      enableHighAccuracy: true,
    },
  })(AddControl)
);
