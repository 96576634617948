import React from 'react';
import { Breadcrumb, Button } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import { withRouter } from 'react-router';
import 'antd/dist/antd.css';

const SubHeader = ({ breadcrumbs, history }) => {
	return (
		<Breadcrumb separator="/" className="breadcrumb-page">
			<Button className="button-back" type="primary" shape="round" icon={<LeftOutlined />} size={24} onClick={() => history.goBack()} />
			{breadcrumbs.map((breadcrumb) => <Breadcrumb.Item>{breadcrumb}</Breadcrumb.Item>)}
		</Breadcrumb>
	);
};

export default withRouter(SubHeader);
