import React, { useEffect, useContext, useState } from 'react';
import { Steps, Descriptions, Badge, Avatar, Row, Col, Spin } from 'antd';
import { useParams } from 'react-router-dom';
import { UserOutlined } from '@ant-design/icons';
import { withRouter } from 'react-router';
import SubHeader from '../SubHeader';
import { Auth } from '../../context/AuthContext';
import app from '../../firebaseConfig';
import 'antd/dist/antd.css';
import './index.css';
import moment from 'moment';
import 'moment/locale/es';
moment.locale('es');

const initialValues = {
  cliente: '',
  planta: '',
  caracterizacion: '',
  fecha: '',
  usuarios: [],
};

const DetailStep = ({ estado, control }) => {
  return (
    <React.Fragment>
      {estado === 0 && (
        <Row>
          <Col span={24}>
            <h2>Hora</h2>
            <p>
              {control.horaInicio
                ? moment(control.horaInicio.toDate()).format('LLL')
                : 'Sin fecha'}
            </p>
          </Col>
          <Col span={24}>
            <h2>Ubicación</h2>
            <p>
              Lat.{' '}
              {control.locationInicio
                ? control.locationInicio.latitude
                : 'Sin latitud'}
            </p>
            <p>
              Lon.{' '}
              {control.locationInicio
                ? control.locationInicio.longitude
                : 'Sin longitud'}
            </p>
            {control.locationInicio ? (
              <iframe
                src={`https://www.google.com/maps?q=${control.locationInicio.latitude},${control.locationInicio.longitude}&output=embed`}
                width="100%"
                height="450"
                frameBorder="0"
                style={{ border: 0 }}
                allowFullScreen={true}
                aria-hidden="false"
                tabIndex="0"
              />
            ) : (
              ''
            )}
          </Col>
        </Row>
      )}
      {estado === 1 && (
        <Row>
          <Col span={24}>
            <h2>Hora</h2>
            <p>
              {control.horaPausa
                ? moment(control.horaPausa.toDate()).format('LLL')
                : 'Sin fecha'}
            </p>
          </Col>
          <Col span={24}>
            <h2>Ubicación</h2>
            <p>
              Lat.{' '}
              {control.locationPausa
                ? control.locationPausa.latitude
                : 'Sin latitud'}
            </p>
            <p>
              Lon.{' '}
              {control.locationPausa
                ? control.locationPausa.longitude
                : 'Sin longitud'}
            </p>
            {control.locationPausa ? (
              <iframe
                src={`https://www.google.com/maps?q=${control.locationPausa.latitude},${control.locationPausa.longitude}&output=embed`}
                width="100%"
                height="450"
                frameBorder="0"
                style={{ border: 0 }}
                allowFullScreen={true}
                aria-hidden="false"
                tabIndex="0"
              />
            ) : (
              ''
            )}
          </Col>
        </Row>
      )}
      {estado === 2 && (
        <Row>
          <Col span={24}>
            <h2>Hora</h2>
            <p>
              {control.horaFinal
                ? moment(control.horaFinal.toDate()).format('LLL')
                : 'Sin fecha'}
            </p>
          </Col>
          <Col span={24}>
            <h2>Ubicación</h2>
            <p>
              Lat.{' '}
              {control.locationFinal
                ? control.locationFinal.latitude
                : 'Sin latitud'}
            </p>
            <p>
              Lon.{' '}
              {control.locationFinal
                ? control.locationFinal.longitude
                : 'Sin longitud'}
            </p>
            {control.locationFinal ? (
              <iframe
                src={`https://www.google.com/maps?q=${control.locationFinal.latitude},${control.locationFinal.longitude}&output=embed`}
                width="100%"
                height="450"
                frameBorder="0"
                style={{ border: 0 }}
                allowFullScreen={true}
                aria-hidden="false"
                tabIndex="0"
              />
            ) : (
              ''
            )}
          </Col>
        </Row>
      )}
    </React.Fragment>
  );
};

const DetailControl = ({ history }) => {
  const { usuario } = useContext(Auth);
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [role, setRole] = useState('');
  const [control, setControl] = useState(initialValues);
  const [current, setCurrent] = useState(1);
  const { Step } = Steps;

  useEffect(() => {
    if (usuario === null) {
      history.push('/login');
    }
    if (usuario !== null) {
      usuario.getIdTokenResult().then(function (idToken) {
        if (
          idToken.claims.role !== 'admin' &&
          idToken.claims.role !== 'consultor'
        ) {
          history.push('/');
        } else {
          setRole(idToken.claims.role);
          app
            .firestore()
            .collection('Controles')
            .doc(id)
            .get()
            .then(function (doc) {
              if (doc.exists) {
                setControl(doc.data());
                setLoading(false);
                if (doc.data().estado === 'En curso') {
                  setCurrent(0);
                } else if (doc.data().estado === 'En pausa') {
                  setCurrent(1);
                } else if (doc.data().estado === 'Finalizado') {
                  setCurrent(2);
                }
              } else {
                // doc.data() will be undefined in this case
                history.push('/');
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      });
    }
  }, [history, usuario, id]);

  const onChange = (current) => {
    setCurrent(current);
  };

  const statusO = (current) => {
    if (current === 0) {
      return 'process';
    } else if (current === 1) {
      return 'finish';
    } else if (current === 2) {
      return 'finish';
    }
  };

  const status1 = (current) => {
    if (current === 0) {
      return 'wait';
    } else if (current === 1) {
      return 'process';
    } else if (current === 2) {
      return 'finish';
    }
  };

  const status2 = (current) => {
    if (current === 0) {
      return 'wait';
    } else if (current === 1) {
      return 'wait';
    } else if (current === 2) {
      return 'finish';
    }
  };

  return (
    <Spin spinning={loading} tip="Cargando...">
      <div className="add-control">
        <SubHeader breadcrumbs={['Home', 'Detalles control']} />
        <div>
          <Descriptions title="" layout="vertical" bordered>
            <Descriptions.Item label="Cliente">
              {control.cliente}
            </Descriptions.Item>
            <Descriptions.Item label="Planta">
              {control.planta}
            </Descriptions.Item>
            <Descriptions.Item label="Caracterización">
              {control.caracterizacion}
            </Descriptions.Item>
            <Descriptions.Item label="Fecha de inicio">
              {control.horaInicio
                ? moment(control.horaInicio.toDate()).format('ll')
                : ' Sin Fecha'}
            </Descriptions.Item>
            <Descriptions.Item label="Estado">
              {control.estado === 'En curso' && (
                <Badge color="#d94a4a" status="processing" text="En curso" />
              )}
              {control.estado === 'En pausa' && (
                <Badge color="#ebbf2f" status="processing" text="En pausa" />
              )}
              {control.estado === 'Finalizado' && (
                <Badge color="#7a9e5e" status="processing" text="Finalizado" />
              )}
            </Descriptions.Item>
            <Descriptions.Item label="Comunidad autónoma">
              {control.comunidad}
            </Descriptions.Item>
            <Descriptions.Item label="Caracterizadores" span={3}>
              {role === 'admin'
                ? control.usuarios.map((usuario, index) => {
                    return (
                      <div key={index} style={{ marginBottom: '8px' }}>
                        <Avatar
                          size={'small'}
                          style={{ backgroundColor: '#326ee6' }}
                          icon={<UserOutlined />}
                        />
                        <span className="detail-user">{usuario}</span>
                      </div>
                    );
                  })
                : control.usuarios.map((usuario) => {
                    var splits = usuario.split(' ');
                    return (
                      <Avatar
                        style={{
                          backgroundColor: '#4a61c2',
                          marginRight: '5px',
                        }}
                      >
                        {splits.length >= 1 ? splits[0].charAt(0) : null}
                        {splits.length >= 2 ? splits[1].charAt(0) : null}
                      </Avatar>
                    );
                  })}
            </Descriptions.Item>
          </Descriptions>
        </div>
        <Steps
          type="navigation"
          current={current}
          onChange={onChange}
          className="detail-progress"
          icon={<UserOutlined />}
        >
          <Step status={statusO(current)} title="Inicio" />
          <Step status={status1(current)} title="Pausa" />
          <Step status={status2(current)} title="Finalizado" />
        </Steps>
        <div className="detail-info">
          {current === 0 && <DetailStep estado={current} control={control} />}
          {current === 1 && <DetailStep estado={current} control={control} />}
          {current === 2 && <DetailStep estado={current} control={control} />}
        </div>
      </div>
    </Spin>
  );
};

export default withRouter(DetailControl);
