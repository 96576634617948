export const DASHBOARD  = '/';
export const SIGN_IN = '/login';
export const INFO_CONTROL = '/control/:id';
export const ADD_CONTROL = '/addControl';
export const DELETE_CONTROL = '/deleteControl/:id';
export const DELETE_CONTROL_SIMPLE = 'deleteControl';
export const DETAIL_CONTROL = '/detailControl/:id';
export const DETAIL_CONTROL_SIMPLE = 'detailControl';
export const USER_LIST = '/usuarios';
export const ADD_USER = '/addUsuario';
export const UPDATE_USER = '/updateUsuario/:id';
export const DELETE_USER = '/deleteUsuario/:id';
export const CLIENT_LIST = '/clientes';
export const ADD_CLIENT = '/addCliente';
export const UPDATE_CLIENT = '/updateCliente/:id';
export const UPDATE_CLIENT_SIMPLE = 'updateCliente';
export const DELETE_CLIENT = '/deleteCliente/:id';
export const DELETE_CLIENT_SIMPLE = 'deleteCliente';