import React, { useState, useContext, useEffect } from 'react';
import { Row, Col } from 'antd';
import { User, Clock, Menu, Plus } from 'react-feather';
import { withRouter } from 'react-router';
import { Auth } from '../../context/AuthContext';
import app from '../../firebaseConfig';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/Routes';
import Ecoembes from './images/ecoembes.png';
import SM from './images/logo.png';
import 'antd/dist/antd.css';
import './index.css';
import moment from 'moment';
import 'moment/locale/es';
moment.locale('es');

function Header() {
  const { usuario } = useContext(Auth);
  const [sidenav, setSidenav] = useState('');
  const [role, setRole] = useState('');
  const [name, setName] = useState('');
  const [client, setClient] = useState('');

  useEffect(() => {
    if (usuario !== null) {
      usuario.getIdTokenResult().then(function (idToken) {
        setRole(idToken.claims.role);
        setName(idToken.claims.name);
        setClient(idToken.claims.client.toLowerCase());
      });
    }
  }, [usuario]);

  return (
    <div className="header-content-mobile">
      <div className="header-mobile">
        <Row>
          <Col span={4}>
            <Menu
              className="icon-menu-mobile"
              onClick={() => setSidenav('showSidenav')}
            />
          </Col>
          <Col span={16}>
            <h3 className="title-mobile">Caracterizaciones</h3>
          </Col>
          <Col span={4}>
            <Link to={ROUTES.ADD_CONTROL}>
              <Plus className="icon-plus-mobile" />
            </Link>
          </Col>
        </Row>
        <Row className="subheader-mobile">
          <Col span={17} lg={20}>
            <div className="icon-content-mobile">
              <User className="icon-header-mobile" />
              <h3>{name}</h3>
            </div>
            <div className="icon-content-mobile">
              <Clock className="icon-header-mobile" />
              <h4>{moment().format('LLL')}</h4>
            </div>
          </Col>
          <Col span={7} lg={4} style={{ margin: 'auto' }}>
            {client === 'ecoembes' && (
              <img
                src={Ecoembes}
                style={{ width: '95px', height: 'auto' }}
                alt="Logo ecoembes"
              />
            )}
            {client === 'sistemas medioambientales' && (
              <img
                src={SM}
                style={{
                  width: '50px',
                  height: 'auto',
                  verticalAlign: 'middle',
                }}
                alt="Logo sistemas medioambientales"
              />
            )}
          </Col>
        </Row>
      </div>
      <aside className={`sidebar ${sidenav}`}>
        <nav className="nav">
          <ul>
            <li>
              <a className="closebtn" onClick={() => setSidenav('')}>
                &times;
              </a>
            </li>
            <li>
              <Link to={ROUTES.DASHBOARD}>
                <a onClick={() => setSidenav('')}>Home</a>
              </Link>
            </li>
            {role === 'admin' && (
              <div>
                <li>
                  <Link to={ROUTES.USER_LIST}>
                    <a onClick={() => setSidenav('')}>Usuarios</a>
                  </Link>
                </li>
                <li>
                  <Link to={ROUTES.CLIENT_LIST}>
                    <a onClick={() => setSidenav('')}>Clientes</a>
                  </Link>
                </li>
              </div>
            )}
            <li>
              <a onClick={() => app.auth().signOut()}>Cerrar Sesión</a>
            </li>
          </ul>
        </nav>
      </aside>
    </div>
  );
}

export default withRouter(Header);
