import React from 'react';
import * as ROUTES from './constants/Routes';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { AuthContext } from './context/AuthContext';
import LogIn from './components/LogIn';
import AddControl from './components/AddControl';
import DeleteControl from './components/DeleteControl';
import InfoControl from './components/InfoControl';
import Header from './components/Header';
import Layout from './components/Layout';
import UserList from './components/UserList';
import AddUsuario from './components/AddUser';
import UpdateUsuario from './components/UpdateUser';
import DeleteUsuario from './components/DeleteUser';
import ClientList from './components/ClientList';
import AddClient from './components/AddClient';
import DeleteClient from './components/DeleteClient';
import UpdateClient from './components/UpdateClient';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import 'antd/dist/antd.css';
import DetailControl from './components/DetailControl';

function App() {
  return (
    <AuthContext>
      <ToastContainer
        position="top-center"
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Router>
        <Switch>
          <Route exact path={ROUTES.SIGN_IN}>
            <LogIn />
          </Route>
          <Route exact path={ROUTES.DASHBOARD}>
            <Header />
            <Layout />
          </Route>
          <Route exact path={ROUTES.ADD_CONTROL}>
            <Header />
            <AddControl />
          </Route>
          <Route exact path={ROUTES.DELETE_CONTROL}>
            <Header />
            <DeleteControl />
          </Route>
          <Route exact path={ROUTES.DETAIL_CONTROL}>
            <Header />
            <DetailControl />
          </Route>
          <Route exact path={ROUTES.INFO_CONTROL}>
            <Header />
            <InfoControl />
          </Route>
          <Route exact path={ROUTES.USER_LIST}>
            <Header />
            <UserList />
          </Route>
          <Route exact path={ROUTES.ADD_USER}>
            <Header />
            <AddUsuario />
          </Route>
          <Route exact path={ROUTES.UPDATE_USER}>
            <Header />
            <UpdateUsuario />
          </Route>
          <Route exact path={ROUTES.DELETE_USER}>
            <Header />
            <DeleteUsuario />
          </Route>
          <Route exact path={ROUTES.CLIENT_LIST}>
            <Header />
            <ClientList />
          </Route>
          <Route exact path={ROUTES.ADD_CLIENT}>
            <Header />
            <AddClient />
          </Route>
          <Route exact path={ROUTES.UPDATE_CLIENT}>
            <Header />
            <UpdateClient />
          </Route>
          <Route exact path={ROUTES.DELETE_CLIENT}>
            <Header />
            <DeleteClient />
          </Route>
          <Route path="*">
            <Redirect to="/" />
          </Route>
        </Switch>
      </Router>
    </AuthContext>
  );
}

export default App;
