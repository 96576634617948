import React, { useState, useEffect, useContext } from 'react';
import { withRouter } from 'react-router';
import { Auth } from '../../context/AuthContext';
import { Link } from 'react-router-dom';
import { Table, Input, Space, Button, Dropdown, Menu, Spin } from 'antd';
import app from '../../firebaseConfig';
import * as ROUTES from '../../constants/Routes';
import 'antd/dist/antd.css';
import './index.css';
import XLSX from 'xlsx';
import {
  PlusOutlined,
  DeleteTwoTone,
  EditTwoTone,
  MoreOutlined,
} from '@ant-design/icons';

import moment from 'moment';
import 'moment/locale/es';
moment.locale('es');

const ClientList = ({ history }) => {
  const { usuario } = useContext(Auth);
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filterClients, setFilterClients] = useState([]);
  const columns = [
    {
      title: 'Nombre',
      dataIndex: 'nombre',
      sorter: (a, b) => a.nombre.localeCompare(b.nombre),
      ellipsis: true,
      showSorterTooltip: false,
    },
    {
      title: 'Fecha de creación',
      dataIndex: 'fecha',
      sorter: (a, b) => a.fecha.localeCompare(b.fecha),
      ellipsis: true,
      showSorterTooltip: false,
    },
    {
      title: ' ',
      key: 'uid',
      width: 120,
      render: (record) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item key="0">
                <Link to={`/${ROUTES.UPDATE_CLIENT_SIMPLE}/${record.uid}`}>
                  <EditTwoTone twoToneColor="#326ee6" /> Editar
                </Link>
              </Menu.Item>
              <Menu.Item key="1">
                <Link to={`/${ROUTES.DELETE_CLIENT_SIMPLE}/${record.uid}`}>
                  <DeleteTwoTone twoToneColor="#d94a4a" /> Eliminar
                </Link>
              </Menu.Item>
            </Menu>
          }
          trigger={['click']}
          placement="topCenter"
        >
          <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
            <Space size="middle">
              <MoreOutlined style={{ fontSize: '24px', color: 'black' }} />
            </Space>
          </a>
        </Dropdown>
      ),
    },
  ];

  useEffect(() => {
    if (usuario === null) {
      history.push('/login');
    }
    if (usuario !== null) {
      usuario.getIdTokenResult().then(function (idToken) {
        if (idToken.claims.role !== 'admin') {
          history.push('/');
        } else {
          app
            .firestore()
            .collection('Clientes')
            .get()
            .then(function (querySnapshot) {
              querySnapshot.forEach(function (doc) {
                const data = {
                  uid: doc.id,
                  nombre: doc.data().nombre,
                  fecha: moment(doc.data().fecha.toDate()).format('LL'),
                };
                setClients((controles) => [...controles, data]);
                setFilterClients((filterControles) => [
                  ...filterControles,
                  data,
                ]);
                setLoading(false);
              });
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      });
    }
  }, [history, usuario]);

  const handleFilter = (e) => {
    const data = clients.filter(
      (item) =>
        item.nombre.toLowerCase().startsWith(e.target.value.toLowerCase()) ||
        item.fecha.toLowerCase().startsWith(e.target.value.toLowerCase())
    );
    setFilterClients(data);
  };

  const exportFile = () => {
    let clients2 = [['Nombre', 'Fecha']];

    clients.map((client) => {
      let userArray = [client.Nombre, client.Fecha];
      clients2.push(userArray);
    });
    const wb = XLSX.utils.book_new();
    const wsAll = XLSX.utils.aoa_to_sheet(clients2);
    XLSX.utils.book_append_sheet(wb, wsAll, 'All UClients');
    XLSX.writeFile(wb, `export-${moment().format('LL')}.xlsx`);
  };

  return (
    <div className="user-table-content">
      <div className="breadcrumb-page search">
        <label>Buscador:</label>
        <Input onChange={handleFilter} />
        <Link to={`/addCliente`}>
          <Button
            className="success-button"
            icon={<PlusOutlined />}
            size="large"
          >
            Añadir Cliente
          </Button>
        </Link>
      </div>
      <Spin spinning={loading} tip="Cargando...">
        <Table
          columns={columns}
          dataSource={filterClients}
          className="table-users"
        />
      </Spin>
    </div>
  );
};

export default withRouter(ClientList);
