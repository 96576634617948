import React, { useCallback, useState } from 'react';
import { Form, Input, Button, Alert } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { withRouter } from 'react-router';
import app from '../../firebaseConfig';
import 'antd/dist/antd.css';
import './index.css';

const FormLogin = ({ history }) => {
  const [error, seterror] = useState('');

  const correoClave = (values) => {
    app
      .auth()
      .signInWithEmailAndPassword(values.email, values.password)
      .then((result) => {
        console.log(result);
        history.push('/');
      })
      .catch((error) => {
        seterror('La contraseña o el usuario no son validos.');
      });
  };

  return (
    <Form
      name="normal_login"
      className="login-form"
      initialValues={{ remember: true }}
      onFinish={correoClave}
      className="form-input-login"
    >
      {error ? (
        <Form.Item>
          <Alert message={error} type="error" />
        </Form.Item>
      ) : null}
      <Form.Item
        name="email"
        rules={[
          {
            required: true,
            message: 'Por favor ingrese su correo electrónico!',
          },
        ]}
      >
        <Input
          prefix={<UserOutlined className="site-form-item-icon" />}
          placeholder="Usuario o Correo electrónico"
        />
      </Form.Item>
      <Form.Item
        name="password"
        rules={[
          { required: true, message: 'Por favor ingrese su contraseña!' },
        ]}
      >
        <Input
          prefix={<LockOutlined className="site-form-item-icon" />}
          type="password"
          placeholder="Contraseña"
          autoComplete="off"
        />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" className="login-form-button">
          Iniciar Sesión
        </Button>
      </Form.Item>
    </Form>
  );
};

export default withRouter(FormLogin);
