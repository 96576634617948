import React from 'react';
import { Link } from 'react-router-dom';
import { Badge } from 'antd';
import 'antd/dist/antd.css';
import './index.css';

import moment from 'moment';
import 'moment/locale/es';
moment.locale('es');

function CardInfo(props) {
  return (
    <Link
      to={
        props.status === 'open' || props.status === 'wait'
          ? `/control/${props.id}`
          : `/`
      }
    >
      <div className={`card-info-container ${props.status}`}>
        <div>
          <p className="card-info-time">{moment(props.hora).format('LL')}</p>
          {props.status === 'open' && (
            <Badge
              className="card-info-status"
              status="success"
              text="En curso"
            />
          )}
          {props.status === 'closed' && (
            <Badge
              className="card-info-status"
              status="danger"
              text="Finalizado"
            />
          )}
          {props.status === 'wait' && (
            <Badge
              className="card-info-status"
              status="warning"
              text="En pausa"
            />
          )}
        </div>
        <div style={{ marginTop: '15px', marginBottom: '15px' }}>
          <h4 style={{ fontSize: '15px', fontWeight: '700' }}>
            {props.nombre}
          </h4>
        </div>
        <div>
          <p className="card-info-footer">
            <span>Por:</span> {props.tecnico}
          </p>
        </div>
      </div>
    </Link>
  );
}

export default CardInfo;
