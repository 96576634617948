import React, { useEffect, useContext, useState } from 'react';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { withRouter } from 'react-router';
import { Auth } from '../../context/AuthContext';
import SubHeader from '../SubHeader';
import DeleteButton from '../DeleteButton';
import 'antd/dist/antd.css';
import * as ROUTES from '../../constants/Routes';
import app from '../../firebaseConfig';

const DeleteClient = ({ history }) => {
	const { usuario } = useContext(Auth);
	const [ submit, setSubmit ] = useState(false);
	const { id } = useParams();

	useEffect(
		() => {
			if (usuario === null) {
				history.push('/login');
			}
			if (usuario !== null) {
				usuario.getIdTokenResult().then(function(idToken) {
					if (idToken.claims.role !== 'admin') {
						history.push('/');
					}
				});
			}
		},
		[ history, usuario ]
	);

	const handleSubmit = (e) => {
		e.preventDefault();
		// ... submit to API or something
		//Get token
		setSubmit(true);
		app
			.firestore()
			.collection('Clientes')
			.doc(id)
			.delete()
			.then(function() {
				toast.info('🚀 Se ha eliminado correctamente!');
				setSubmit(false);
				history.push(ROUTES.CLIENT_LIST);
			})
			.catch(function(error) {
				toast.error('😲 Error en los datos!');
			});
	};

	return (
		<div className="add-control">
			<SubHeader breadcrumbs={[ 'Home', 'Eliminar cliente' ]} />
			<DeleteButton page="cliente" handleSubmit={handleSubmit} loading={submit} />
		</div>
	);
};

export default withRouter(DeleteClient);
