import React, { useState, useEffect, useContext } from 'react';
import { toast } from 'react-toastify';
import { Input, Select, Breadcrumb, Button, Form, Spin } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import * as ROUTES from '../../constants/Routes';
import { Link, useParams } from 'react-router-dom';
import { withRouter } from 'react-router';
import { Auth } from '../../context/AuthContext';
import { useCollectionDataOnce } from 'react-firebase-hooks/firestore';
import app from '../../firebaseConfig';
import 'antd/dist/antd.css';
import './index.css';
import Axios from 'axios';

const { Option } = Select;

const UpdateUser = ({ history }) => {
  const { usuario } = useContext(Auth);
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [submit, setSubmit] = useState(false);
  const [form] = Form.useForm();
  const [clientes, loading2, error] = useCollectionDataOnce(
    app.firestore().collection('Clientes')
  );

  useEffect(() => {
    if (usuario === null) {
      history.push('/login');
    }
    if (usuario !== null) {
      usuario.getIdTokenResult().then(function (idToken) {
        if (idToken.claims.role !== 'admin') {
          history.push('/');
        } else {
          setLoading(true);
          const config = {
            headers: { Authorization: `Bearer ${idToken.token}` },
          };
          Axios.get(
            `https://us-central1-smcontrol-60d81.cloudfunctions.net/api/users/${id}`,
            config
          ).then((res) => {
            const data = {
              displayName: res.data.displayName,
              email: res.data.email,
              password: '',
              role: res.data.role,
              client: res.data.client,
            };
            form.setFieldsValue({ ...data });
            setLoading(false);
          });
        }
      });
    }
  }, [history, usuario, id, form]);

  const onFinish = (values) => {
    setSubmit(true);
    //Get token
    usuario
      .getIdToken()
      .then(function (idToken) {
        const config = {
          headers: { Authorization: `Bearer ${idToken}` },
        };
        Axios.patch(
          `https://us-central1-smcontrol-60d81.cloudfunctions.net/api/users/${id}`,
          {
            displayName: values.displayName,
            password: values.password,
            email: values.email,
            role: values.role,
            client: values.client,
          },
          config
        )
          .then((res) => {
            toast.info('🚀 Se ha guardado correctamente!');
            setSubmit(false);
            history.push(ROUTES.USER_LIST);
          })
          .catch(function (error) {
            // handle error
            toast.error('😲 Error en los datos!');
            console.log(error);
          });
      })
      .catch(function (error) {
        // handle error
        toast.error('😲 Error en los datos!');
      });
  };

  return (
    <div className="add-control">
      <Breadcrumb separator="/" className="breadcrumb-page">
        <Link to={ROUTES.USER_LIST}>
          <Button
            className="button-back"
            type="primary"
            shape="round"
            icon={<LeftOutlined />}
            size={24}
          />
        </Link>
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item>Añadir Usuario</Breadcrumb.Item>
      </Breadcrumb>
      <Spin spinning={loading} tip="Cargando...">
        <Form
          name="update_user"
          form={form}
          initialValues={clientes}
          onFinish={onFinish}
          className="form-container"
        >
          <Form.Item
            label=""
            name="displayName"
            rules={[{ required: true, message: 'Campo obligatorio!' }]}
          >
            <Input placeholder="Nombre" className="input-form" type="text" />
          </Form.Item>
          <Form.Item
            label=""
            name="email"
            rules={[{ required: true, message: 'Campo obligatorio!' }]}
          >
            <Input
              placeholder="Correo electronico"
              className="input-form"
              type="email"
            />
          </Form.Item>
          <Form.Item
            label=""
            name="password"
            rules={[{ required: true, message: 'Campo obligatorio!' }]}
          >
            <Input
              placeholder="Contraseña"
              className="input-form"
              type="password"
            />
          </Form.Item>
          <Form.Item
            label=""
            name="role"
            rules={[{ required: true, message: 'Campo obligatorio!' }]}
          >
            <Select
              placeholder="Selecciona un rol"
              className="input-form"
              bordered={false}
            >
              <Option value="tecnico" key="tecnico">
                Tecnico
              </Option>
              <Option value="admin" key="admin">
                Administrador
              </Option>
              <Option value="consultor" key="consultor">
                Consultor
              </Option>
            </Select>
          </Form.Item>
          {clientes ? (
            <Form.Item
              label=""
              name="client"
              rules={[{ required: true, message: 'Campo obligatorio!' }]}
            >
              <Select
                placeholder="Selecciona un cliente"
                className="input-form"
                bordered={false}
              >
                {clientes.map((item) => (
                  <Option value={item.nombre} key={item.nombre}>
                    {item.nombre}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          ) : null}
          <Form.Item>
            <Button
              className="button-submit"
              type="primary"
              size={24}
              htmlType="submit"
              loading={submit}
            >
              Modificar
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </div>
  );
};

export default withRouter(UpdateUser);
