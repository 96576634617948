import React, { useState, useEffect, useContext } from 'react';
import { toast } from 'react-toastify';
import { Input, Select, Breadcrumb, Button, Spin, Form } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import * as ROUTES from '../../constants/Routes';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import { Auth } from '../../context/AuthContext';
import app from '../../firebaseConfig';
import 'antd/dist/antd.css';
import './index.css';
import Axios from 'axios';

const { Option } = Select;

const AddUser = ({ history }) => {
  const { usuario } = useContext(Auth);
  const [clientes, setClientes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [submit, setSubmit] = useState(false);

  useEffect(() => {
    if (usuario === null) {
      history.push('/login');
    }
    if (usuario !== null) {
      usuario.getIdTokenResult().then(function (idToken) {
        if (idToken.claims.role !== 'admin') {
          history.push('/');
        } else {
          app
            .firestore()
            .collection('Clientes')
            .get()
            .then(function (querySnapshot) {
              querySnapshot.forEach(function (doc) {
                setClientes((clientes) => [...clientes, doc.data()]);
              });
              setLoading(false);
            })
            .catch(function (error) {
              console.log('Error getting documents: ', error);
            });
        }
      });
    }
  }, [history, usuario]);

  const onFinish = (values) => {
    //Get token
    setSubmit(true);
    usuario
      .getIdToken()
      .then(function (idToken) {
        const config = {
          headers: { Authorization: `Bearer ${idToken}` },
        };
        Axios.post(
          `https://us-central1-smcontrol-60d81.cloudfunctions.net/api/users`,
          {
            displayName: values.nombre,
            password: values.password,
            email: values.email,
            role: values.rol,
            client: values.cliente,
          },
          config
        ).then((res) => {
          toast.success('🚀 Se ha guardado correctamente!');
          setSubmit(false);
          history.push(ROUTES.USER_LIST);
        });
      })
      .catch(function (error) {
        // handle error
        toast.error('😲 Error en los datos!');
      });
  };

  return (
    <div className="add-control">
      <Breadcrumb separator="/" className="breadcrumb-page">
        <Link to={ROUTES.USER_LIST}>
          <Button
            className="button-back"
            type="primary"
            shape="round"
            icon={<LeftOutlined />}
            size={24}
          />
        </Link>
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item>Añadir Usuario</Breadcrumb.Item>
      </Breadcrumb>
      <Spin spinning={loading} tip="Cargando...">
        <Form name="add_control" onFinish={onFinish} className="form-container">
          <Form.Item
            label=""
            name="nombre"
            rules={[{ required: true, message: 'Campo obligatorio!' }]}
          >
            <Input placeholder="Nombre" className="input-form" type="text" />
          </Form.Item>
          <Form.Item
            label=""
            name="email"
            rules={[{ required: true, message: 'Campo obligatorio!' }]}
          >
            <Input
              placeholder="Correo electronico"
              className="input-form"
              type="email"
            />
          </Form.Item>
          <Form.Item
            label=""
            name="password"
            rules={[{ required: true, message: 'Campo obligatorio!' }]}
          >
            <Input
              placeholder="Contraseña"
              className="input-form"
              type="password"
            />
          </Form.Item>
          <Form.Item
            label=""
            name="rol"
            rules={[{ required: true, message: 'Campo obligatorio!' }]}
          >
            <Select
              placeholder="Selecciona un rol"
              className="input-form"
              bordered={false}
            >
              <Option value="tecnico" key="tecnico">
                Tecnico
              </Option>
              <Option value="admin" key="admin">
                Administrador
              </Option>
              <Option value="consultor" key="consultor">
                Consultor
              </Option>
            </Select>
          </Form.Item>
          <Form.Item
            label=""
            name="cliente"
            rules={[{ required: true, message: 'Campo obligatorio!' }]}
          >
            <Select
              placeholder="Selecciona un cliente"
              className="input-form"
              bordered={false}
            >
              {clientes.map((cliente) => (
                <Option key={cliente.nombre}>{cliente.nombre}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item>
            <Button
              className="button-submit"
              type="primary"
              size={24}
              htmlType="submit"
              loading={submit}
            >
              Añadir
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </div>
  );
};

export default withRouter(AddUser);
