import React, { useState, useEffect, useContext } from 'react';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router';
import { Auth } from '../../context/AuthContext';
import app from '../../firebaseConfig';
import { Spin, Breadcrumb, Button, Alert } from 'antd';
import * as ROUTES from '../../constants/Routes';
import { LeftOutlined } from '@ant-design/icons';
import { Link, useParams } from 'react-router-dom';
import 'antd/dist/antd.css';
import './index.css';

import { geolocated } from 'react-geolocated';
import Location from './images/location.svg';

import moment from 'moment';
import 'moment/locale/es';
moment.locale('es');

const initialFormData = {
  horaInicio: moment(),
  usuarios: [],
};

const InfoControl = ({
  history,
  coords,
  isGeolocationAvailable,
  isGeolocationEnabled,
}) => {
  const { usuario } = useContext(Auth);
  let { id } = useParams();
  const [control, setControl] = useState(initialFormData);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (usuario === null) {
      history.push('/login');
    }
    if (usuario !== null) {
      app
        .firestore()
        .collection('Controles')
        .doc(id)
        .get()
        .then(function (doc) {
          setControl({ ...doc.data() });
          setLoading(false);
        })
        .catch(function (error) {
          console.log('Error getting documents: ', error);
        });
    }
  }, [history, usuario, id]);

  const handleFinalizar = (e) => {
    e.preventDefault();
    // ... submit to API or something
    app
      .firestore()
      .collection('Controles')
      .doc(id)
      .set({
        ...control,
        horaFinal: moment().toDate(),
        locationFinal: {
          latitude: coords.latitude,
          longitude: coords.longitude,
        },
        estado: 'Finalizado',
      })
      .then(function () {
        toast.info('🚀 Se ha guardado correctamente!');
        history.push('/');
      })
      .catch(function (error) {
        toast.error('😲 Error en los datos!');
      });
  };

  const handlePausar = (e) => {
    e.preventDefault();
    // ... submit to API or something
    app
      .firestore()
      .collection('Controles')
      .doc(id)
      .set({
        ...control,
        horaPausa: moment().toDate(),
        locationPausa: {
          latitude: coords.latitude,
          longitude: coords.longitude,
        },
        estado: 'En pausa',
      })
      .then(function () {
        toast.info('🚀 Se ha guardado correctamente!');
        history.push('/');
      })
      .catch(function (error) {
        toast.error('😲 Error en los datos!');
      });
  };

  const handleReanudar = (e) => {
    e.preventDefault();
    // ... submit to API or something
    app
      .firestore()
      .collection('Controles')
      .doc(id)
      .set({
        ...control,
        horaReanudacion: moment().toDate(),
        locationReanudacion: {
          latitude: coords.latitude,
          longitude: coords.longitude,
        },
      })
      .then(function () {
        toast.info('🚀 Se ha guardado correctamente!');
        history.push('/');
      })
      .catch(function (error) {
        toast.error('😲 Error en los datos!');
      });
  };

  const handleDelete = (e) => {
    e.preventDefault();
    // ... submit to API or something
    app
      .firestore()
      .collection('Controles')
      .doc(id)
      .delete()
      .then(function () {
        toast.info('🚀 Se ha eliminado correctamente!');
        history.push('/');
      })
      .catch(function (error) {
        toast.error('😲 Error en los datos!');
      });
  };

  return !isGeolocationAvailable ? (
    <div className="location-wrap">
      <img
        src={Location}
        style={{ height: '200px', width: '200px' }}
        alt="location img"
      />
      <h2>Su navegador no admite Geolocalización</h2>
    </div>
  ) : !isGeolocationEnabled ? (
    <div className="location-wrap">
      <img
        src={Location}
        style={{ height: '200px', width: '200px' }}
        alt="location img"
      />
      <h2>La geolocalización no está habilitada</h2>
    </div>
  ) : coords ? (
    <div className="add-control">
      <Breadcrumb separator="/" className="breadcrumb-page">
        <Link to={ROUTES.DASHBOARD}>
          <Button
            className="button-back"
            type="primary"
            shape="round"
            icon={<LeftOutlined />}
            size={24}
          />
        </Link>
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item>Información Control</Breadcrumb.Item>
      </Breadcrumb>
      <Spin spinning={loading} tip="Cargando...">
        <div className="form-container">
          <div className="add-control-containter">
            <h4 style={{ color: '#8f8f8f' }}>Hora Inicio:</h4>
            <h3 style={{ margin: 0 }}>
              {moment(control.horaInicio.toDate()).format('LLLL')}
            </h3>
          </div>
          {control.horaPausa ? (
            <div className="add-control-containter">
              <h4 style={{ color: '#8f8f8f' }}>Hora Pausa:</h4>
              <h3 style={{ margin: 0 }}>
                {moment(control.horaPausa.toDate()).format('LLLL')}
              </h3>
            </div>
          ) : null}
          {control.horaFinal ? (
            <div className="add-control-containter">
              <h4 style={{ color: '#8f8f8f' }}>Hora Final:</h4>
              <h3 style={{ margin: 0 }}>
                {moment(control.horaFinal.toDate()).format('LLLL')}
              </h3>
            </div>
          ) : null}
          <div className="add-control-containter">
            <h4 style={{ color: '#8f8f8f' }}>Planta:</h4>
            <h3 style={{ margin: 0 }}>{control.planta}</h3>
          </div>
          <div className="add-control-containter">
            <h4 style={{ color: '#8f8f8f' }}>Caracterización:</h4>
            <h3 style={{ margin: 0 }}>{control.caracterizacion}</h3>
          </div>
          <div className="add-control-containter">
            <h4 style={{ color: '#8f8f8f' }}>Usuarios:</h4>
            <h3 style={{ margin: 0 }}>
              {control.usuarios.map((usuario) => ` ${usuario} ·`)}
            </h3>
          </div>
          {control.horaReanudacion ? (
            <Alert
              style={{ width: '100%' }}
              message="Información"
              description="Este control ya ha sido pausado una vez."
              type="info"
              showIcon
              className="alert-info"
            />
          ) : control.horaPausa ? (
            <Button
              className="button-submit-blue"
              type="primary"
              size={24}
              onClick={handleReanudar}
            >
              Reanudar
            </Button>
          ) : (
            <Button
              className="button-submit-blue"
              type="primary"
              size={24}
              onClick={handlePausar}
            >
              Pausar
            </Button>
          )}
          <Button
            className="button-submit-purple"
            type="primary"
            size={24}
            onClick={handleFinalizar}
          >
            Finalizar
          </Button>
          <Button
            className="button-submit-red"
            type="primary"
            size={24}
            onClick={handleDelete}
          >
            Eliminar
          </Button>
        </div>
      </Spin>
    </div>
  ) : (
    <div className="location-wrap">
      <img
        src={Location}
        style={{ height: '200px', width: '200px' }}
        alt="location img"
      />
      <h2>Obteniendo los datos de ubicación...</h2>
      <Spin style={{ marginTop: '20px' }} size="large" />
    </div>
  );
};

export default withRouter(
  geolocated({
    positionOptions: {
      enableHighAccuracy: true,
    },
  })(InfoControl)
);
